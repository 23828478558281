export const filterItems = () => {
    const form = document.querySelectorAll("[vdx-filters-filters]");
    //console.log(form);
    if (form.length === 0) return;
    const listItems = document.querySelectorAll("[vdx-filters-items]");
    
 
    if (listItems.length === 0) return;
    const formInputs = form[0].querySelectorAll('input');
    const checkboxes = Array.from(formInputs).filter(input => input.type === "checkbox");
    
    const radios = Array.from(formInputs).filter(input => input.type === "radio");
    if (!checkboxes.length && !radios.length) return;
    const search = form[0].querySelector('[vdx-filters-search]');
    if (!search) return;

    checkboxes.forEach(checkbox => {
        checkbox.addEventListener('change', (e) => {
            console.log('checkbox', checkbox);
            const otherCheckedCheckboxes = Array.from(checkboxes).filter(checkbox => checkbox !== e.target && checkbox.checked);
            if (otherCheckedCheckboxes.length > 0) {
                console.log(`Other checked checkboxes: ${otherCheckedCheckboxes.map(checkbox => checkbox.nextElementSibling.textContent).join(', ')}`);
                const checked = e.target.checked;
                const label = e.target.nextElementSibling.textContent;
                console.log(`Checkbox for ${label} is ${checked ? 'checked' : 'unchecked'}`);
                listItems.forEach(listItem => {
                    if (listItem.textContent.includes(label)) {
                        if (checked) {
                            console.log('checked');
                            listItem.style.display = 'block';
                            e.target.closest('label').classList.add('vdx--checked');
                        } 
                        if (!checked) {
                            console.log('unchecked');
                            if (!otherCheckedCheckboxes.some(checkbox => listItem.textContent.includes(checkbox.nextElementSibling.textContent))) {
                                listItem.style.display = 'none';
                                e.target.closest('label').classList.remove('vdx--checked');
                            }
                        }
                        if (checkboxes.every(checkbox => !checkbox.checked)) {
                            console.log('every');
                            listItems.forEach(listItem => {
                                listItem.style.display = 'block';
                                e.target.closest('label').classList.remove('vdx--checked');
                            });
                
                        }
                    }
                    if (!listItem.textContent.includes(label)) {
                        if(checked) {   
                            console.log(`List item does not include label: ${label}`);
                            e.target.closest('label').classList.add('vdx--checked');
                        }
                        else {
            
                            e.target.closest('label').classList.remove('vdx--checked');
                        }
                    }
                });
            }
            
            else {
                listItems.forEach(listItem => {
                    listItem.style.display = 'none';
                });
                const checked = e.target.checked;
                const label = e.target.nextElementSibling.textContent;
                console.log(`Checkbox for ${label} is ${checked ? 'checked' : 'unchecked'}`);
                listItems.forEach(listItem => {
                    if (listItem.textContent.includes(label)) {
                        if (checked) {
                            listItem.style.display = 'block';
                            e.target.closest('label').classList.add('vdx--checked');
                        } 
                        else {
                            listItems.forEach(listItem => {
                                listItem.style.display = 'block';
                                e.target.closest('label').classList.remove('vdx--checked');
                            });
                        }
                    }
                    if (!listItem.textContent.includes(label)) {
                        if(checked) {   
                            console.log(`List item does not include label: ${label}`);
                            e.target.closest('label').classList.add('vdx--checked');
                        }
                        else {
                            listItem.style.display = 'block';
                            e.target.closest('label').classList.remove('vdx--checked');
                        }
                    }
                });
            }
        });
    });

    radios.forEach(radio => {
        radio.addEventListener('change', (e) => {
            listItems.forEach(listItem => {
                listItem.style.display = 'none';
            });
            radios.forEach(radio => {
                radio.closest('label').classList.remove('vdx--checked');
            });
            const checked = e.target.checked;
            const label = e.target.nextElementSibling.textContent;
            console.log(`Radio for ${label} is ${checked ? 'checked' : 'unchecked'}`);
            if (checked) {
                listItems.forEach(listItem => {
                    if (listItem.textContent.includes(label)) {
                        listItem.style.display = 'block';
                        e.target.closest('label').classList.add('vdx--checked');
                    }
                });
            }
        });
    });

    let searchTimer;
    search.addEventListener('input', (e) => {
        clearTimeout(searchTimer);
        searchTimer = setTimeout(() => {
            const searchValue = e.target.value.toLowerCase();
            listItems.forEach(listItem => {
                if (listItem.textContent.toLowerCase().includes(searchValue)) {
                    listItem.style.display = 'block';
                } else {
                    listItem.style.display = 'none';
                }
            });
        }, 500);
    });
}